export const apiConfig = {
  rest: {
    url: 'https://dev-govj-wp-gw.klefki.io/scooby-iam',
    // url: 'https://staging-govj-wp-gw.klefki.io/scooby-iam',
  },
  gql: {
    url: 'https://dev-govj-wp-gw.klefki.io/scooby-gql',
    // url: 'https://staging-govj-wp-gw.klefki.io/graphql',
  },
};
